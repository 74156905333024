import { PageProps } from 'gatsby'

import { FC } from 'react'

import { getColumnById } from '~/components/kanban/helpers'
import { withPage } from '~/components/page'
import ColumnDetailView from '~/views/ColumnDetail/ColumnDetail'

interface Props extends PageProps {
	params: {
		id: string
	}
}

const ColumnDetailPage: FC<Props> = props => {
	const columnData = getColumnById(props.params.id)
	if (!columnData) {
		return <div>Column not found</div>
	}
	return <ColumnDetailView columnData={{ ...columnData, archive: false }} />
}

export default withPage(ColumnDetailPage, {
	public: false,
	name: 'Column Details',
})
